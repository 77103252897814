.wizard__container::v-deep {
    background: unset !important;
    box-shadow: unset !important;
    padding: 1em;
    overflow: visible;

    .wizard__wrapper {
        .wizard__header--container {
            .wizard__header--wrapper {
                box-shadow: unset !important;

                .v-stepper__step {
                    flex-basis: 110px;
                    padding: 24px 10px;

                    .v-stepper__step__step {
                        color: transparent;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;

                        .v-icon {
                            svg {
                                path {
                                    stroke: $primary-color !important;
                                    fill: $primary-color !important;
                                }
                            }
                        }
                    }

                    .v-stepper__label {
                        text-align: center;
                    }

                    &.v-stepper__step--active {
                        .v-stepper__label {
                            color: $secondary-color;
                        }
                    }

                    &:not(.v-stepper__step--active) {
                        .v-stepper__step__step {
                            border: 6px solid #ececec !important;
                            background-color: $secondary-color !important;
                        }

                        .v-stepper__label {
                            color: $secondary-color-light;
                        }
                    }

                    &.v-stepper__step--complete {
                        .v-stepper__step__step {
                            border: unset !important;
                            background-color: $primary-color !important;
                            border-color: $primary-color !important;
                        }

                        & + .v-divider {
                            border-color: $primary-color;
                        }
                    }

                    &.v-stepper__step--editable {
                        .v-stepper__step__step {
                            background-color: $primary-color !important;
                        }
                    }
                }

                .v-divider {
                    border: 3px solid #dadada;
                    margin: 33px -40px 0;
                }
            }
        }

        .wizard__content--container {
            padding-top: 0;
            overflow: visible;

            .wizard__content--wrapper {
                overflow: visible;

                .wizard__content--item {
                    padding: 0;

                    .v-stepper__wrapper {
                        overflow: visible;
                    }

                    .wizard__content--item-container {
                        .wizard__content--item-wrapper {
                            &:first-child {
                                .wizard__content--item-content {
                                    padding-top: 24px !important;
                                    border-top: 6px solid #d9ecfc;
                                    border-radius: 4px 4px 0 0;
                                }
                            }

                            .wizard__content--item-wrapper--row {
                                padding-bottom: 12px;
                            }

                            .wizard__content--item-content {
                                background-color: #fff;
                                padding: 0 32px 16px;
                                padding-bottom: 0;
                                position: relative;

                                .v-label {
                                    color: $secondary-color;
                                    font-weight: bold;
                                    font-size: 1rem;
                                }

                                .v-input {
                                    label {
                                        font-weight: normal;
                                    }
                                }
                            }

                            .wizard__content--item-actions {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                flex-wrap: wrap;
                                background-color: #fff;
                                padding: 24px;
                                border-radius: 0 0 4px 4px;
                            }

                            .wizard__content--item-info {
                                padding: 0 0 0 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}
